import React, { Component } from 'react';
import './view-bags.scss';
import AlertDialog from '../../components/alert-dialog';
import AlertImage from "../../components/alert-image";
import Header from '../common/header';
import ItemBreakfast from '../../components/item-bag';
import { GetBreakfasts, GetModalities, GetTypes, GetQuestions, GetCountry, GetCountries, GetCities, GetLastSales, GetPromotions, SearchProducts } from "../../reducers";
import { connect } from "react-redux";
import Session from '../../sandbox/session';
import Swal from "sweetalert2";
import * as Consts from "../common/constants/const";
import { parse, stringify } from 'flatted';
import TypeServicesContent from "../../components/type-services-content";
// import {Helmet} from "react-helmet";
// import ItemInfoImageTwoTexts from "../../components/item-info-image-two-texts";
import { converter } from "../../lib/currency-converter";
// import ContentImagesStepsBuy from "../../components/content-images-steps-buy";
// import ContentPercentSales from "../../components/content-percent-sales";
class ViewBags extends Component {

  constructor(props) {
    super(props);
    this.state = {
      f3: false,
      faq: false,
      titlef3: Consts.txSelCat,
      filterActive: 0,
      bags: [],
      contentBreakfasts: [],
      storeItems: [],
      viewSelect: 3,
      modalitie: Consts.modalitieInitial,
      type: "",
      ocassion: "",
      showContent: true,
      currencyUser: Session.instance.currencyUser,
      titleResultSection: "Productos Sugeridos",
      textToSearch: []
    };
  }

  UNSAFE_componentWillMount() {
    // this.getPrice();
    if (!Session.instance.countries.length)
      this.props.GetCountries();
    this.props.GetCountry();
    this.props.GetQuestions();
  }

  componentWillUnmount() {
    this.props.GetCountries();
    document.removeEventListener('scroll', this.trackScrolling);
  }

  getPrice = async () => {
    let count = "1000";
    let data = await converter.convert({
      to: Session.instance.currencyUser,
      from: "COP",
      amount: parseFloat(count)
    }).then((result) => {
      return result.result;
      // get the result
    }).catch((err) => {
      // catch the error
    });
    return data;
  }

  componentDidMount() {
    //this.changeViewModalities(this.state.modalitie);
    //this.props.GetTypes(this.state.viewSelect);
    this.props.GetBreakfasts("undefined", false);
    this.props.GetLastSales();
    this.props.GetPromotions();
    this.props.GetModalities();

    let storeItems = localStorage.getItem('itemsshop');
    if (storeItems === null)
      storeItems = [];
    else
      storeItems = parse(storeItems);
    this.setState({
      storeItems: storeItems
    });
    if (this.props.questions)
      Session.instance.questions = this.props.questions;
    if (this.props.country)
      Session.instance.country = this.props.country;

    document.addEventListener('scrollend', this.trackScrolling);
  }

  isBottom(element) {
    return element?.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('products-principal-page');
    if (this.isBottom(wrappedElement)) {
      if (Session.instance.nextpage !== false && Session.instance.nextpage !== null) {
        this.props.GetBreakfasts(Session.instance.nextpage, false);
      } else {
        document.removeEventListener('scrollend', this.trackScrolling);
      }


    }
    if (Session.instance.nextpage == null) {
      document.removeEventListener('scrollend', this.trackScrolling);
    }
  };


  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.modalitie && nextProps.modalitie.toLowerCase() !== this.state.modalitie.toLowerCase()) {
      let item = nextProps.modalities.filter((mo) => mo["title"].toLowerCase() === nextProps.modalitie.toLowerCase())[0];
      if (item !== undefined) {
        this.setState({
          modalitie: item["title"].toLowerCase()
        });
        this.changeViewModalities(item["title"]);
      }
    }
    if (nextProps.type ||
      nextProps.ocassion) {
      if (
        nextProps.type.toLowerCase() !== this.state.type.toLowerCase() || nextProps.ocassion.toLowerCase() !== this.state.ocassion.toLowerCase()) {
        let valid = nextProps.type !== undefined && nextProps.ocassion !== undefined;
        if (valid) {
          this.setState({
            type: nextProps.type,
            ocassion: nextProps.ocassion
          });
          this.filterBy(nextProps.type, nextProps.ocassion);
        }
      }
    }
    if (this.props.questions && Session.instance.questions !== this.props.questions) {
      Session.instance.questions = this.props.questions;
    }
    if (this.props.country && Session.instance.country !== this.props.country) {
      Session.instance.country = this.props.country;
    }

  }


  mountBreakfasts(bags) {
    var content = [];

    bags.forEach((breakf, i) => {
      let Itemc = <ItemBreakfast ref={'selbag-' + breakf['title']} size="item3" bag={breakf} questions={this.props.questions} />
      content = [...content, Itemc]
    });
    if (this.state.contentBreakfasts !== content) {
      this.setState({
        contentBreakfasts: content
      });
    }
  }


  selectCategory() {
    var contentCategorys = [];
    if (this.props.types) {
      this.props["types"].forEach((cat) => {
        var contentOcc = [];

        if (cat["subcategories"] !== undefined) {
          cat["subcategories"].forEach((occ) => {
            if (occ.image) {
              contentOcc.push(
                <a href={`#/filtrar/productos/${cat["title"]}/${occ["title"]}/`}>
                  <div onClick={() => { console.log("Filter by 135: ", this, cat, occ) }} className={"cio-image cnt-item-ocation"} style={{ backgroundImage: `url(${occ.image})` }}>
                    <p className={"cio-im-s"} key={occ["title"]}>{occ["title"]}</p>
                  </div></a>
              );
            } else
              contentOcc.push(
                <a href={`#/filtrar/productos/${cat["title"]}/${occ["title"]}/`}>
                  <div onClick={() => { console.log("142: Filter by", this, cat, occ) }} className={"cnt-item-ocation"}>
                    <span key={occ["title"]}>{occ["title"]}</span>
                  </div>
                </a>);
          });
          contentCategorys.push(this.itemCategory(cat["title"], contentOcc));
        }
      });
    } else {
      contentCategorys = [
        <div className={"d-f jc-c ai-c fd-c"}>
          <abbr title="Consejo: Para ver las categorias debes seleccionar una modalidad."><span className={"txt-sel-mod"}>Primero selecciona una modalidad</span></abbr>
          <TypeServicesContent onSearch={this.onSearchProducts} showContent={this.onSetShowContent} colorTitle={"c-g"} modalitie={this.state.modalitie} changeView={this.changeViewModalities} modalities={this.props.modalities} />
        </div>];
    }

    return (
      <div className="content-dody-cat">
        {contentCategorys}
      </div>
    );
  }

  itemCategory(title, occasions) {

    return (
      <div key={title} className="content-categorys">
        <div className="content-title">
          <span>&nbsp; {title}</span>
        </div>
        <div className="content-occasions">
          {occasions}
        </div>
      </div>
    )
  }

  filterBy = (typ, ocass) => {
    var type = typ.replace(/ /gi, "_");
    var ocassion = ocass.replace(/ /gi, "_");
    this.props.GetBreakfasts(`category/${type}/ocassion/${ocassion}`, true);
    if (this.state.bags !== this.props.bags) {
      this.setState({
        bags: this.props.bags
      }, () => {
        this.selectCategory3();
      });

    }
  };

  selectCategory3 = () => {
    this.setState({ bags: [], f3: !this.state.f3, filterActive: 3 });
  };

  viewFaq = () => {
    this.setState({ faq: !this.state.faq });
  };


  cancelFilter = () => {
    this.setState({ "f3": false, "f2": false, "f1": false, })
  };

  confirmFilter = () => {
    this.setState({
      f3: false,
      f2: false,
      f1: false,
    });

  };

  verifyRepeat = async (dict, item) => {
    var repeat = false;
    if (dict !== undefined) {
      await dict.forEach((pr) => {
        if (pr["identifier"] === item["identifier"]) {
          repeat = true;
        }
      });
      return repeat;
    }
    return repeat;
  };

  addBreakfastToStore = async (inBreakfast, redire) => {
    var bag = inBreakfast;
    delete bag["current"];
    if (redire === true) {
      document.getElementById("shopp").click()
    }

    var repeat = false;
    var products = this.state.storeItems;
    repeat = await this.verifyRepeat(products, bag);

    if (!repeat) {
      products = [...products, ...[bag]];

      localStorage.setItem('itemsshop', stringify(products));

      this.setState({
        storeItems: products
      }, () => {
      });
    }
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: Consts.txProdAdd,
      showConfirmButton: false,
      timer: 1000
    });
  };

  deleteBreakfastToStore = async (bag, redirect) => {

    var products = this.state.storeItems;

    var products2 = await products.filter((p) => { return p["identifier"] !== bag["identifier"] });
    this.setState({
      storeItems: products2
    }, () => {
    });
    await localStorage.setItem('itemsshop', stringify(products2));

  };

  changeViewModalities = (view) => {
    this.props.GetTypes(view);
    this.props.GetBreakfasts(`modalitie/${view}`, true);
  };

  onUpdatePrices = () => {
    this.setState({
      currencyUser: Session.instance.currencyUser
    });
  }

  onSearchProducts = (data) => {
    console.log("onSearchProducts", data);
    this.setState({
      textToSearch: data
    });
    if (data.length > 3)
      this.props.SearchProducts("", data);
  }


  render() {
    let contentProducts = [];
    // let contentItemWelcome = [];
    let contentPromotions = [];
    let itemsPromotions = [];
    let itemsLastSales = [];
    let itemsProducts = [];
    let itemsSearch = [];
    let contentLastSales = [];
    // let contentPercentSales = [];
    // let contentImagesStepsBuy = [];
    // let contentColumnTwoWelcome = [];
    let contentFilter = [];
    let contentResultSearch = [];
    let titlePrincipalSection = this.state.titleResultSection;

    if (this.props.setdata_search.length > 0) {
      this.props.setdata_search.forEach((brk, i) => {
        itemsSearch.push(<ItemBreakfast ref={"itembag-" + i + "-" + brk['title']} currencyUser={this.state.currencyUser} questions={this.props.questions} id={brk.id} size="item3" bag={brk} onAdd={this.addBreakfastToStore} onDelete={this.deleteBreakfastToStore} inStore={this.verifyRepeat(this.state.storeItems, brk)} />);
      });
    }

    if (this.props.bags.length > 0)
      this.props.bags.forEach((brk, i) => {
        itemsProducts.push(<ItemBreakfast ref={"itembag-" + i + "-" + brk['title']} currencyUser={this.state.currencyUser} questions={this.props.questions} id={brk.id} size="item3" bag={brk} onAdd={this.addBreakfastToStore} onDelete={this.deleteBreakfastToStore} inStore={this.verifyRepeat(this.state.storeItems, brk)} />);
      });
    if (this.props.promotions.length > 0)
      this.props.promotions.forEach((promotion, i) => {
        let promotions = [];
        promotion.products.forEach((product) => {
          promotions.push(
            <ItemBreakfast ref={"itembag-" + i + "-" + product['title']} currencyUser={this.state.currencyUser} questions={this.props.questions} id={product.id} size="item2" bag={product} onAdd={this.addBreakfastToStore} onDelete={this.deleteBreakfastToStore} inStore={this.verifyRepeat(this.state.storeItems, product)} />
          )
        });
        itemsPromotions.push(<>
          <h3>{promotion.title}</h3>
          {promotions}
        </>);
      });
    if (this.props.lastsales.length > 0)
      this.props.lastsales.forEach((brk, i) => {
        itemsLastSales.push(<ItemBreakfast ref={"itembag-" + i + "-" + brk['title']} currencyUser={this.state.currencyUser} questions={this.props.questions} id={brk.id} size="item2" bag={brk} onAdd={this.addBreakfastToStore} onDelete={this.deleteBreakfastToStore} inStore={this.verifyRepeat(this.state.storeItems, brk)} />);
      });

    if (this.state.textToSearch.length > 3) {
      if(itemsSearch.length === 0) {
        
      }
      contentResultSearch = (
        <div>
          <div className={"py-2"}>
            <h1>{itemsSearch.length} resultados para la busqueda de {this.state.textToSearch}</h1>
          </div>
          <div id="content-search" className="body-view py-0040">
            {itemsSearch}
            
          </div>

        </div>
      )

    }
    if (this.props.modalitie != null || this.props.ocassion != null || this.props.types != null) {
      titlePrincipalSection = "Resultado";
      contentFilter = (
        <div onClick={this.selectCategory3} className="content-btn-filter">
          <span className="title-filter">{Consts.txSelCat}</span>
          <div className="icon-filter"></div>
        </div>
      );
    } else {
      // contentItemWelcome = <ItemInfoImageTwoTexts title={"Bienvenido a Artesanias Colombia"} txt-aux={"El mejor lugar para comprar"}></ItemInfoImageTwoTexts>;
      contentPromotions = (
        <div>
          <div className={"py-2"}>
            <h1>PROMOCIONES</h1>
          </div>
          <div id="content-promotions" className="body-view">
            {itemsPromotions}
          </div>
        </div>
      );
      contentLastSales = (
        <div>
          <div>
            <h1>VENDIDOS RECIENTEMENTE</h1>
          </div>
          <div id="content-lastsales" className="body-view">
            {itemsLastSales}
          </div>
        </div>
      );
      // contentImagesStepsBuy = (
      //   <ContentImagesStepsBuy></ContentImagesStepsBuy>
      // );
      // contentPercentSales = (
      //   <ContentPercentSales></ContentPercentSales>
      // );

      // contentColumnTwoWelcome = (
      //   <div className={"content-column-welcome-two"}>
      //     {contentImagesStepsBuy}
      //     <div className={"d-f jc-c ai-c ct-item-discount"}>
      //       <div className="icon-sale" />
      //       {contentPercentSales}
      //     </div>
      //     <div className={"ct-icon-contact"}>
      //       <div className="icon-contact" />
      //     </div>
      //   </div>
      // );
    }
    contentProducts = (
      <div>
        <div className={"py-2"}>
          <h1>Resultado</h1>
        </div>
        <div id="products-principal-page" className="body-view">
          {itemsSearch}
          {itemsProducts}
        </div>
      </div>
    );

    return (

      <div className="body-content-view">
        {/* <Helmet>
         <meta charSet="utf-8" />
         <title>Productos a domicilo en bogota | ArtesaniasGb</title>
       </Helmet>
       <title>Productos a domicilo en bogota | Productos </title> */}

        {this.state.f3 || this.state.f2 || this.state.f1 ? <AlertDialog title={Consts.txSelCat} content={this.selectCategory()} onCancel={() => { this.cancelFilter(); }} onConfirm={() => this.confirmFilter()} /> : <div></div>}
        {this.state.faq ? <AlertImage onCancel={() => this.viewFaq()} image={`${Consts.urlStatic}images/artesaniasgbinfo.png`} /> : <div></div>}

        <Header updatePrices={this.onUpdatePrices} header={<TypeServicesContent onSearch={this.onSearchProducts} showContent={this.onSetShowContent} modalitie={this.state.modalitie} changeView={this.changeViewModalities} modalities={this.props.modalities} />} storeItems={this.state.storeItems} />
        {
          this.state.showContent && <section className=" cv2 content-view">

            <article className="column-filter-bag">

              <div className={`section-products size3 ${window.innerWidth < 700 ? "style-pzed" : ""}`}>
                {/* {contentItemWelcome}
               {contentColumnTwoWelcome} */}
                {contentResultSearch}
                {contentFilter}
                {contentLastSales}
                {contentPromotions}
                <div className={"py-2"}>
                  <h1>{titlePrincipalSection} {this.props.modalitie} {this.props.ocassion}</h1>
                </div>
                <div className="body-view">
                  {contentProducts}
                </div>
              </div>
            </article>
            {/* <section  className="column-create-bag">

             <div className="icons-pay">
               <div className="icon-cc"></div>
               <div className="icon-efect"></div>
               <div className="icon-wunion"></div>
             </div>
             <aside>
              
               <div className={"ct-truck-home content-icon-truck"}>
                 <div className={"content-items-truck d-f jc-c ai-c"}>
                   <h2>Envío GRATIS por compras superiores a $50.000</h2>
                 
                 </div>
               </div>
               <div className={"content-icon-trending"}>
                 <div className={"icon-trending"}></div>
                 <div className={"content-items-trending d-f jc-c ai-c"}>
                   <h2>Tendencia</h2>
                 </div>
               </div>
             </aside>
               <div onClick={() => {   window.location.hash = "#/surprise";
             }} className="button-create-bag">
               <div className="icon-create">

               </div>
               <span>Crea el<br/>&nbsp;&nbsp;tuyo</span>
             </div>
             <div className="ts-third-column">
               <span className="reg-cp">{Consts.rightsRes}</span>

               <div onClick={this.viewFaq} className="content-faq">
                 <div className="icon-faq"></div>
               </div>
             </div>
           </section> */}

          </section>
        }

      </div>
    )
  }

  onSetShowContent = () => {
    this.setState({
      showContent: !this.state.showContent
    });
  }


}

export default connect(
  (state) => ({ cities: state.setInfoData.cities, countries: state.setInfoData.countries, bags: state.setInfoData.bags, modalities: state.setInfoData.modalities, types: state.setInfoData.types, questions: state.setInfoData.questions, country: state.setInfoData.country, lastsales: state.setInfoData.lastsales, promotions: state.setInfoData.promotions, setdata_search: state.setInfoData.setdata_search }),
  { GetBreakfasts, GetTypes, GetModalities, GetQuestions, GetCountry, GetCountries, GetCities, GetLastSales, GetPromotions, SearchProducts })
  (ViewBags);
//background: rgb(222,54,0);
//background: linear-gradient(90deg, rgba(222,54,0,0.7301295518207283) 6%, rgba(0,255,160,1) 48%, rgba(181,186,18,1) 100%);
