import React, { Component } from 'react';
import './field-info.scss';

class FieldInfo extends Component {

  editValue = (e) => {
    console.log(typeof(this.props.editValue), this.props);
    if (typeof (this.props.editValue) === "function") {
      this.props.editValue(this.props.state, e.target.value, this.props.pattern);
    }
  };

  endEditValute = (e) => {
    if (typeof (this.props.endEditValue) === "function") {
      this.props.endEditValue(this.props.state, e.target.value, this.props.pattern);
    }
  };

  render() {
    var classContent = "content-item";
    var cond = this.props.value && this.props.value.length > 0;
    classContent += cond ? " edit" : "";
    var error = !this.props.error ? "error-item" : "";
    return (
      <div className={`${classContent} ${error}`}>
        {this.props.title !== null ? <label className="title-item">{this.props.title}</label> : <label></label>}
        <input autoComplete='on' maxLength={this.props.maxSize} value={this.props.value} type="text" onMouseLeave={this.endEditValute} onFocusOut={this.endEditValute} onChange={this.editValue} placeholder={this.props.place || ""} />
      </div>
    );
  }
}

export default FieldInfo;