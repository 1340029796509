import React, {Component} from 'react';
import {formatCop }from '../../rsc/utils';
import './item-store.scss';
import * as Consts from '../../pages/common/constants/const';

class ItemStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bag: [],
      screenQuantity: false,
      bagQuantity: props.bag.quantity
    }
  }

  componentDidMount() {
    this.setState({
      bag: this.props.bag,
      bagQuantity: this.props.bag.quantity
    });
  }
  /*
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.bag  !== this.props.bag){
      this.setState({
        bag: this.props.bag
      });
    }
  }*/




  render() {

    const { state, props } = this;
    const { bag } = props;
    var contentProducts = [];
    var contentOptionsQuantity = [];
    var count = 1;

    contentProducts = bag["products"] ? bag["products"].map((prod) => {
      return (<span ref={prod["title"]}>{prod["title"]}</span>);
    }): bag["description"];

    // var quantity = new Array(bag["quantity"]);
    for(count > 1; count <= bag["quantity"]; count++) {
      var sel = count === bag["quantity"];
      if(sel)
        contentOptionsQuantity.push(<option selected={sel} ref={count}>{count}</option>);
    }
    var newPrice = bag["quantity"]*bag["price"];
    return (
        <div className="content-item-store">
          {
            state.screenQuantity &&
              <div className={"cnt-sel-quantity"}>
                <div className="body-sel-quantity d-f expand jc-se">
                  <button disabled={bag.quantity <= 1} onClick={() => {this.changePrivateQuantity(state.bagQuantity-1, bag);}}><strong>-</strong></button>
                  {
                      <div className='field-count-interactive'>
                        <input disabled={ bag.quantity <= 0 || bag.quantity >= bag.quantity_store-1} onChange={(e) => e.target.value && this.changePrivateQuantity(e.target.value, bag)} value={state.bagQuantity} className="sel-quantity cur-ptr" defaultValue={state.bagQuantity} type="number" min="1" max={bag.quantity_store}/>
                      </div>
                  }
                  <button disabled={bag.quantity >= bag.quantity_store - 1} onClick={() => {this.changePrivateQuantity(state.bagQuantity+1, bag);}}><strong>+</strong></button>
                </div>
              </div>
          }
          <div className="content-image">
            {
              bag["image"] && bag["image"].length > 0 && bag["image"][0]["drive_key"] && bag["image"][0]["drive_key"].split("mp4").length > 1 ?
              <video className={`image-item`}controls src={`${Consts.urlStatic}${bag["image"][0]["drive_key"]}`}></video> :
                <div className={`image-item ${bag["image"] && bag["image"].length > 0 ? "" : "bs-ct"}`}style={{backgroundImage:`url(${Consts.urlStatic}${bag["image"] && bag["image"][0] ? bag["image"][0]["drive_key"] : "images/im-n-disp.png"})`}}>
                </div>
            }


          </div>
          <div className="content-information">
            <div className="col1-information">
              <div className="cola1-inf">
                <span className="title-item">{bag["title"]}</span>
                <div onClick={this.activateScreenQuantity}>
                  <span className="title-cantidad">Cantidad</span>
                  <select onChange={bag["quantity"] < bag["quantity_store"] ? (e) => this.onChangeCantidad(e, bag) : () =>{}} className="sel-cantidad  pe-n">
                    {contentOptionsQuantity}
                  </select>
                </div>
                <span className="title-cantidad">Disponible {bag.quantity_store}</span>

                <span onClick={()=>{props.deleteProduct(bag)}} className="btn-delete">X  <span className={"txt-delete-it"}>Eliminar</span></span>
              </div>
              {
                bag["products"] !== undefined ?
                  <div className="content-contenido">
                    {contentProducts}
                    {
                     /*
                     <div className="col2-information">
                      <span className="btn-addition">Adicionar</span>
                    </div>
                    */
                    }
                  </div> : []
              }
            </div>

          </div>
          <div className="content-price">
            <span>Precio Unitario</span>
            <span className="price">{formatCop(bag["price"] && bag["price"].toString())}</span>
            <span>Precio</span>
            <span className="price"><b>{formatCop(newPrice.toString())}</b></span>
          </div>
        </div>
    );

  }

  activateScreenQuantity = () => {
    this.setState({
      screenQuantity: !this.state.screenQuantity
    });
  }


  onChangeQuantity = (value, bag) => {
    let newQuantity = value-bag.quantity;
    this.props.changeProduct(bag, newQuantity);
  };

  decrement(bag, s) {
    this.props.changeProduct(bag, -1);
  }
  increment(bag, s, maxCount) {
    this.props.changeProduct(bag, 1);
  }

  changePrivateQuantity = async (newQuantityIn, bag) => {
    let newQuantity = newQuantityIn;
    if(newQuantity >= bag.quantity_store ) {
      newQuantity = bag.quantity_store-1;
    }
    this.setState({bagQuantity:parseInt(newQuantity)});
    await this.onChangeQuantity(newQuantity, bag);
  }
}

export default ItemStore
