import React, {Component} from 'react';
import './information-product.scss';

import Header from '../common/header';
import 'sweetalert2/src/sweetalert2.scss'
import "react-datepicker/dist/react-datepicker.css";
import  * as Consts from "../common/constants/const";
import Session from "../../sandbox/session";
import moment from "moment";
import {
  formatCop
} from "../../rsc/utils";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {GetBag} from "../../reducers";
import {parse, stringify} from 'flatted';

class InformationProduct extends  Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
    this.props.GetBag(this.props.product);
  }

  onBuyProduct = () => {
    localStorage.setItem('itemsshop', stringify([...parse(localStorage.getItem("itemsshop")), this.props.bag]));
    window.location.hash = "#/tienda";
  }

  render() {
    let setBag = this.props.bag;
    let contentComments = [];
    if(setBag["comments"] && setBag["comments"].length > 0) {

      setBag["comments"].forEach((com) => {
        contentComments.push(
          <div className="media-inf-view-prd">

            <div className="media-body mg-l-15">
              <strong className={"c-b"}>{com["name"]}&nbsp;</strong>
              <span>{com["message"]}</span>
              <h6 className={"mg-0 ta-e"}>{moment(com["date"]).format("MMM DD hh:mm a")}</h6>
            </div>
          </div>
        );
      });
    } else {
      contentComments = [<span className={"c-f ta-c"}>Sin comentarios, para agregar un comentario debes comprar este producto</span>];
    }


    let accStars = 0;
    let contentCalifications = [];
    let countStarsBreakfast  = 5;
    if(setBag["califications"] && setBag["califications"].length > 0) {
      setBag["califications"].forEach((com) => {
        var countStars = parseInt(com["question_qualify"]) + parseInt(com["question_punctuality"]) + parseInt(com["question_expected"]) + parseInt(com["question_price"]) + parseInt(com["question_design"]);
        countStars = Math.ceil(countStars / 5);
        accStars += countStars
        var contentStars = []

        var xC = 0;
        for( xC = 0; xC < countStars; xC++) {
          contentStars.push(<span className={"estrella llena"}></span>)
        }
        for( xC = 0; xC < 5-countStars; xC++) {
          contentStars.push(<span className={"estrella"}></span>)
        }
        contentCalifications.push(
          <div className="media-inf">

            <div className="media-body mg-l-15 content-califications">
              <strong className={" c-b"}>{com["name"]}</strong>
              <div className={"ct-cal-view0"}>
                {contentStars}
              </div>
              {Session.instance.questions &&
              <div className={"ct-cal-view1"}>
                <span className={"tx-it-q fz-xs d-f jc-sb"}>{Session.instance.questions["question_qualify"]}<span><span className={"estrella llena"}></span>{com["question_qualify"]}</span></span>
                <span className={"tx-it-q fz-xs d-f jc-sb"}>{Session.instance.questions["question_punctuality"]}<span><span className={"estrella llena"}></span>{com["question_punctuality"]}</span></span>
                <span className={"tx-it-q fz-xs d-f jc-sb"}>{Session.instance.questions["question_expected"]}<span><span className={"estrella llena"}></span>{com["question_expected"]}</span></span>
                <span className={"tx-it-q fz-xs d-f jc-sb"}>{Session.instance.questions["question_price"]}<span><span className={"estrella llena"}></span>{com["question_price"]}</span></span>
                <span className={"tx-it-q fz-xs d-f jc-sb"}>{Session.instance.questions["question_design"]}<span><span className={"estrella llena"}></span>{com["question_design"]}</span></span>
              </div>}

              <h6 className={"mg-0 ta-e c-b"}>{moment(com["date"]).format("MMM DD hh:mm a")}</h6>
            </div>
          </div>
        );
      });
      countStarsBreakfast  = Math.ceil(accStars/setBag["califications"].length);
    }
    else {
      contentCalifications = [<span className={"c-f ta-c"}>Sin Calificaciones, para calificar debes comprar este producto</span>];
    }


    let contentProducts = [];
    if(setBag["products"]) {
      setBag["products"].forEach((prod) => {
        contentProducts.push(<span className={"h-2-5"} ref={prod["title"]}>∙{prod["title"]}</span>);
      });
    }

    return <body  className="body-content-view">
    <Helmet>
      <meta charSet="utf-8" />
      <title>{this.props.product} | ArtesaniasGb</title>
    </Helmet>
    <Header header={<h2 className={"title-page"}>{this.props.product} | ArtesaniasGb</h2>} store={false} />
    <article className="content-view">
      <section className={"sect-inf-prod"}>
        <div className={"it-sec sect-image"}>
          {
            setBag.quantity < 1 && <div className={"content-view-products content-sold-out d-f jc-c ai-c cp-n"}>
              <span> SOLD OUT</span>
            </div>
          }
          <img style={{backgroundImage: `url(${setBag["image"] && setBag["image"][0] && setBag["image"][0]["drive_key"]})`}} alt=""/>
        </div>

      </section>
      <section className={"sect-inf-prod2"}>
        <div className={"it-sec d-f fd-r"}>
          <div className={"subit-sec"}><h3>Comentarios</h3> {contentComments}</div>
          <div className={"subit-sec"}><h3>Calificaciones</h3>{contentCalifications}</div>
        </div>
      </section>
      <section className={"sect-inf-prod"}>
        <div className={"it-sec d-f fd-c jc-sa ai-c"}>
          <div className={"d-f fd-r ct-sub3"}>
            <h3 className={"c-t"}>Precio: {formatCop(setBag["price"])} <small>{Consts.txCurrency}</small></h3>
            <span className={"ct-cal"}>+{countStarsBreakfast}<span className={"estrella llena"}></span></span>

          </div>
          <div className={"d-f fd-r"}><span>Contenido: </span>{contentProducts}</div>
        </div>
        <div className={"it-sec d-f jc-c ai-c fd-c"}>
          <h3 className="vendidos">{setBag.sales} {Consts.txSold}</h3><div onClick={() => this.onBuyProduct()} ><span  className="button-continue">{Consts.txBuy}</span></div>
        </div>
      </section>
    </article>
    </body>
  }


}



export default connect(
  (state) => ({bag: state.setInfoData.bag}),
  {GetBag})
(InformationProduct);
