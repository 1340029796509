import React, {Component} from "react";
import './location-delivery.scss';

class LocationDelivery extends Component{
  constructor(props) {
    super(props);

    this.state = {
      locationSelected:"",
      setCountry: [
        "Colombia",
        "Brazil",
        "Argentina",
      ],
      selectingLocation: true
    }

  }

  render() {
    return <div className={"d-f jc-c ai-c"}>
      <button onClick={this.selectLocation} className={"d-f fd-r jc-c ai-c cur-p"}>
        <span>Entrega a <span className={"c-pr"}>{this.props.Country}, {this.props.City} </span></span>
        <div className={"icon-location"}> </div>
      </button>

    </div>
  }

  selectCategory() {
    return <div></div>
  }

  selectLocation = () => {
    this.getLocation();
    this.props.onSelectLocation()


    //Swal.fire({title:'Select location to shipping!', content:(<ItemInformation state="Country" pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/} title="Country" data={this.state.setCountry} value={this.state.locationSelected} typeInput="select" type="text" valid={true} changeValue={this.selectLocation}/>)});
  }

  cancelFilter = () => {

  }

  confirmFilter = () => {

  }

  getLocation = () => {

    let id;
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 10000,
    };

    const target = {
      latitude: 0,
      longitude: 0,
    };
        
    function success(pos) {
      const crd = pos.coords;
    
      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);

      if (target.latitude === crd.latitude && target.longitude === crd.longitude) {
        console.log("Congratulations, you reached the target");
        navigator.geolocation.clearWatch(id);
      }

    }
    
    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }
    
    id = navigator.geolocation.getCurrentPosition(success, error, options);

    
    
  }

}

export default LocationDelivery