import Axios from "axios";

export const formatCop = (val) => {
  if(val){
    var valor = val;
    var invV = invt(valor);
    invV = invV.split("");
    var counter = 1;
    var result = "";
    invV.forEach((v) => {
      var ad = v;
      if(counter === 3) {
        ad = v+".";
        counter = 0;
      }
      result = result + ad;

      counter++;
    });
    var cad = invt(result);
    if(cad.charAt(0) === "."){
      cad = cad.substring(1,cad.string);
    }
    return `$ ${cad}`;
  }
};

export const invt = (val) => {
  var x = val.length;
  var newX = "";
  while(x>= 0) {
    newX = newX + val.charAt(x);
    x--;
  }
  return newX;
};

export const getClientIp = async () => {
  return await Axios.get("https://api.ipify.org/?format=json").then((res)=>{console.log(res);return res["data"]["ip"]});
}

export const getJSessionId = () => {
  var jsId = document.cookie.match(/ID=[^;]+/);
  if(jsId !== null) {
    if (jsId instanceof Array)
      jsId = jsId[0].substring(11);
    else
      jsId = jsId.substring(11);
  } else {
    let r = Math.random().toString(36).substring(3);
    jsId=r;
  }
  return jsId;
}
export const groupByNumber = (lim, array) => {
  let newArray = [[]];
  array.forEach((elm, i) => {
    if(newArray[newArray.length-1].length === lim) {
      newArray.push([]);
    }
    newArray[newArray.length-1].push(elm);
  });
  return newArray;
}
