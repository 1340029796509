import React, { Component } from 'react';
import './slide-menu.scss';
import { Link } from "react-router-dom";
import * as Consts from "../../pages/common/constants/const";

class SlideMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modV: 0
    };
  }

  changeMod = (v) => {
    this.setState({
      modV: v.id
    }, () => {
      this.props.changeView(v.title);
      return;
    });
  };

  render() {
    let modalities = this.props.modalities;
    let platformMobile = false;
    let contentMod = [];
    if (this.props.modalities.length === undefined) {
      modalities = [{ "id": 0, "title": "Number one" }, { "id": 1, "title": "Number two" }, { "id": 2, "title": "Number three", }, { "id": 3, "title": "Number Four", }];
    }
    if (!this.state.menuActive && this.props.modalities.length > 0) {
      modalities = this.props.modalities.filter((moda) => moda["title"].toLowerCase() === this.props.modalitie.toLowerCase());
      var clientWidth = document.documentElement.clientWidth;


      if (clientWidth < 900) {
        platformMobile = true;
        modalities = [...modalities, ...this.props.modalities.filter((moda) => moda["title"].toLowerCase() !== this.props.modalitie.toLowerCase()).slice(0, 1)];
      } else
        if (clientWidth < 700) {
          // modalities = modalities;
        } else {
          modalities = [...modalities, ...this.props.modalities.filter((moda) => moda["title"].toLowerCase() !== this.props.modalitie.toLowerCase()).slice(0, 3)];
        }
    }
    modalities.forEach((moda) => {
      contentMod.push(
        <Link key={moda["title"] + '-item'} onClick={this.state.menuActive && this.openMenu} to={`/productos/${moda["title"]}`}>
          <li>
            <div className={"ic-services-ic"}></div>
            <h3 className={`${moda["title"].toLowerCase() === this.props.modalitie.toLowerCase() ? "is-active" : ""}  m-01`}>
              <abbr title={`Filtrar: productos ${moda["title"]}`}>{moda["title"]} </abbr>
            </h3>
          </li>
        </Link>);
    });
    return (
      <div className={`content-services ${this.state.menuActive ? "cnt-serv-act" : ""}`}>
        
        <div>
          {
            this.state.menuActive && <a href={'/'}>
              <div className="img-comp">
                <h2 className="title-artesaniasgb">{Consts.txTitle}</h2>
              </div>
            </a>
          }
          {
            // <form class="example" action="action_page.php">
                /* <input type="text" placeholder="Search.." name="search"/> */
            // </form>
            }
          <span className={`c-w ${this.props.colorTitle}`}><abbr title={"Que te trae por aqui hoy?"}>Modalidades</abbr></span>
        </div>
        <ul className={`d-f ov-au ${this.state.menuActive ? "menu-ls-active" : ""}`}>

          {contentMod}

        </ul>
        <div>
          <div onClick={this.openMenu} className={`icon-menu ${platformMobile ? "itm-menu-active" : ""} cur-p`}></div>
        </div>

      </div>
    );
  }
  openMenu = () => {

    this.props.showContent();
    this.setState({
      menuActive: !this.state.menuActive
    });

  }
}

export default SlideMenu;
