import React, {Component} from 'react';
import './item-information.scss';
import '../../pages/common/constants/const.js';

class ItemInformation extends Component {
  constructor(props) {
    super(props);
    this._title = this.props.title.split(" ")[0];
    
    this.state = {
      'value': "",
      'listItems': [],
      'editField': false,
      'days_delivery': ""
    };
    this._mountSelect = false;
    
  }
  
  UNSAFE_componentWillMount() {
    this._title = this.props.title.split(" ")[0];
    this.validateData();
    if (this.props.value) {
      this.setState({
        'value': this.props.value,
        'valid': this.props.value.length === 0 ? true : this.props.valid,
      }, () => {
      
      });
    }
  }
  
  componentDidMount() {
    // this.changeValue(this.state.value);
    
  }
  
  
  UNSAFE_componentWillUpdate() {
    
    if (this.props.typeInput === "select" && this._mountSelect === false) {
      this.validateData();
      if (this.props.title.split(" ")[0] !== this._title && this.state.value !== "" && this._title !== "Amount") {
        this._title = this.props.title.split(" ")[0];
        this.setState({
          value: ""
        });
        this.changeValue(this.state.value);
        
      }
    }
  }
  
  validateData = () => {
    if (this._title === "State") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "Bank") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "Ciudad") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "País") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "Documento") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "Tipo") {
      this.mountSelect(this.props.data);
    }
    
    if (this._title === "Entity") {
      this.mountSelect(this.props.data);
    }
    
    if (this._title === "AccountType") {
      this.mountSelect(this.props.data);
    }
    if (this._title === "Payment") {
      this.mountSelect(this.props.data);
    }
  };
  
  
  spaceCard = (val, charact, replace, complete) => {
    var characters = charact || 4;
    var reg = new RegExp(".{" + characters + "}", "g");
    var value = val.replace(reg, function (a, b, c) {
      return (a + replace);
    });
    complete(value);
  };
  
  checkExpAmount = (value, range, exp) => {
    return value.substring(range[0], range[1]) === exp;
    
  };
  
  changeValue = (e) => {
    var newValue = e.target !== undefined ? e.target.value : e;
    console.log("in changeValue", newValue, this._title, newValue.length <= this.props.lengthMax, newValue.length, this.props.lengthMax);

    if (this._title === "Expiration") {
      if (newValue.length < 5) {
        var dateExp = newValue.replace(/ /g, "").replace("/", "");
        this.spaceCard(dateExp, 2, " / ", complete => {
          if (complete) {
            newValue = complete;
            if (newValue.substring(newValue.length - 2, newValue.length) === "/ ") {
              newValue = newValue.substring(0, newValue.length - 3);
            }
          }
        });
      }
    }
    
    if (this._title === "Card" && this.props.title !== "Card Nickname") {
      if (newValue.length < 12) {
        var patSpace = / /g;
        var newVal = "";
        newValue = newValue.replace(patSpace, newVal);
      }
    }
    
    if (this._title === "Amount") {
      var accounting = require("accounting");
      newValue = newValue.toString();
      var valueUnformat = newValue.replace(".00", "").replace("$", "").replace(",", "").replace(".", "");
      var validateLastZero = /[.]\d[.]\d\d$/;
      if (this.checkExpAmount(newValue, [newValue.length - 3, newValue.length], ".00") || !newValue.includes(".")) {
        if (this.checkExpAmount(newValue, [newValue.length - 4, newValue.length - 3], ".") || this.checkExpAmount(newValue, [newValue.length - 5, newValue.length - 4], ".")) {
          if (this.checkExpAmount(valueUnformat, [valueUnformat.length - 2, valueUnformat.length], "..")) {
            newValue = `$${valueUnformat.slice(0, valueUnformat.length - 1)}`;
          }
        } else {
          if (!newValue.includes(".") && this.state.value.length > newValue.length) {
            newValue = newValue.slice(0, newValue.length);
          } else {
            if (newValue === "$0.0" || newValue === "$.00") {
              newValue = "$";
            } else {
              if (parseInt(valueUnformat) <= 50000) {
                newValue = accounting.formatMoney(newValue);
              } else {
                var validateThou = /^\d{6}$/;
                if (validateThou.test(parseInt(valueUnformat))) {
                  newValue = accounting.formatMoney(valueUnformat.slice(0, -1));
                } else
                  newValue = newValue.slice(0, -3);
              }
            }
          }
        }
      } else {
        if (newValue.substring(newValue.length - 2, newValue.length) === ".0" || newValue.substring(newValue.length - 1, newValue.length) === /[^0-9$]/g + '') {
          //newValue = newValue
        }
        if (this.checkExpAmount(newValue, [newValue.length - 2, newValue.length], "..") || newValue.split(".")[1].length > 2) {
          newValue = newValue.substring(0, newValue.length - 1);
        }
      }
      this.codycross = false;
      if(validateLastZero.test(newValue)) {
        this.codycross = true;
        newValue = newValue.replace(".00", "0");
      }
      if(newValue.length === 0) {
        newValue = "$";
        this.setState({
          'valid': true
        });
      }
    }
    
    if (this._title === "Zip") {
      newValue = newValue.slice(0, 5);
      this.setState({
        'valid': false
      });
    }
    if (this.props.lengthMax === undefined || newValue.length <= this.props.lengthMax) {
      this.setState({
        value: newValue && newValue.slice(0, this.props.lengthMax),
      }, () => {
        this.props.changeValue(this.props.state, this.state.value, this.props.pattern, this.state.days_delivery);
      });
    }
    
  };
  
  mountSelect = (data) => {
    if (typeof data == "object" && data !== null && data.length > 0) {
      var items = [];
      data.forEach((item) => {
        item = item['name'] || item;
        if (item.description === this.props.value) {
          items.push(<option id={item.days_delivery} selected>{item.description}</option>);
        } else
          items.push(<option id={item.days_delivery}>{item.description}</option>);
      });
      this.setState({
        'listItems': items
      }, () => {
        this._mountSelect = true;
        return this._mountSelect;
      });
    }
  };
  
  validateNextField = (e) => {
    if(e) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if(keyCode === 40 || keyCode === 13) {
        var i =0;
        for(i; i < e.target.parentNode.parentElement.childNodes.length; i++){
          if(e.target.parentNode.parentElement.childNodes[i].childNodes[1] === e.target) {
            e.target.parentNode.parentElement.childNodes[i+1].click();
          }
        }
      }
    }
  };
  
  viewEditField =() => {
    this.setState({
      'editField': !this.state.editField
    });
    //this.inputNow.focus();
  };
  
  render() {
    var value = this.state.value;
    if (((this.state.value !== this.props.value || this.props.value) && this._title !== "Amount")) {
      value = this.props.value;
      this._title = this.props.title.split(" ")[0];
      
    }
    var itemValueClass = this.props.title !== "Email" ? "capitalize" : "";
    if (value) {
      itemValueClass += this.props.valid === true || this.state.valid === true ? "" : " error";
    }
    return (
      <div className="item-information" onClickCapture={
        this.viewEditField
      }>
        <span className={this.props.valid === true ? "" : "errortitlefield"}>{this.props.title}</span>
        {this.props.typeInput === "select" ?
          <select onChangeCapture={(e) => {
            this.props.changeValue(this.props.state, e.target.value, this.props.pattern, e.target[e.target.selectedIndex].id);
          }
          }>
            {[...[<option>Seleecione un valor</option>], ...this.state.listItems]}
          </select>
          
          : this.state.editField === false ?
            <span className={`edit-field ${itemValueClass}`} onClick={this.viewEditField}>{value || this.props.place}</span>
            :
            < input autoFocus={true} ref = {(thiss) => {
                this.inputNow = thiss;
              }} disabled={this.props.disabled} onKeyUp={() => {
                if (this._title === "Amount" && value) {
                var newValue2;
                var newValue = value;
                newValue2 = newValue.replace(".00", "").replace("$", "");
                if(this.codycross) {
                  this.inputNow.setSelectionRange(this.state.value.length-1, this.state.value.length-1);
                } else
                  this.inputNow.setSelectionRange(newValue2.length + 1, newValue2.length + 1);
                }
              }} className={itemValueClass} value={value} onKeyPress={this.validateNextField}  onChangeCapture={this.changeValue} type={this.props.type}
                maxLength={this.props.lengthMax} placeholder={this.props.placeholder}
                pattern={this.props.type === "number" ? "[0-9]*" : ""}
                inputMode={this.props.type === "number" ? "numeric" : ""}
                onBlur={this.viewEditField}/>
          
        }
      </div>
    
    
    );
  }
}

export default ItemInformation;
