import React, { Component } from 'react';
import Session from '../../sandbox/session';
import './item-bag.scss';
import { formatCop } from "../../rsc/utils";
import moment from 'moment';
import * as Consts from "../../pages/common/constants/const";
import { converter } from "../../lib/currency-converter/index";
import Swal from 'sweetalert2/dist/sweetalert2.js'

class ItemBreakfast extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewProducts: false,
      viewComments: false,
      viewCalifications: false,
      priceUsd: 0,
      lastCurrency: undefined
    };
    this.classStore = "";

    this.disponibility = false;
  }

  UNSAFE_componentWillMount() {
    // this.getPrice(this.props.bag['price']);
    this.setState({
      bag: this.props.bag,
      lastCurrency: Session.instance.currencyUser
    });


  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.state.lastCurrency !== nextProps.currencyUser) {
      this.setState({
        lastCurrency: nextProps.currencyUser
      });
      this.getPrice(this.props.bag['price']);

    }
  }

  componentDidMount() {
    this.itemBreakfast.addEventListener("mouseenter", (e) => {
      if (this.itemBreakfast.className === `item ${this.props.size} binstore` || this.itemBreakfast.className === `item ${this.props.size} binstore item-hov-mos`) {
        this.itemBreakfast.className = `item ${this.props.size} binstore item-hov-mos`
      } else {
        this.itemBreakfast.className = `item ${this.props.size} item-hov-mos`

      }
      this.droneS.className = "dron-content moved";
    });
    this.itemBreakfast.addEventListener("mouseleave", (e) => {
      if (this.itemBreakfast.className === `item ${this.props.size} binstore` || this.itemBreakfast.className === `item ${this.props.size} binstore item-hov-mos`) {
        this.itemBreakfast.className = `item ${this.props.size} binstore`
      } else {
        this.itemBreakfast.className = `item ${this.props.size}`

      }
      this.droneS.className = "dron-content";
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.props.inStore.then((f) => {
      this.classStore = f ? "binstore" : "";
    });


  }

  deleteFromStore = () => {

  };

  getPrice = async (count) => {

    let data = await converter.convert({
      to: this.props.currencyUser,
      from: "COP",
      amount: parseFloat(count)
    }).then((result) => {
      this.setState({
        priceUsd: result.result
      });
      return result.result;
      // get the result
    }).catch((err) => {
      // catch the error
    });
    return data;
  }

  render() {
    var contentProducts = [];
    var contentComments = [];
    var contentCalifications = [];
    var contentStars = [];

    if (this.props.bag["products"] && this.props.bag["products"].length > 0) {
      this.props.bag["products"].forEach((prod) => {
        contentProducts.push(<span className={"h-2-5"} ref={prod["title"]}>∙{prod["title"]}</span>);
      });
    } else {
      contentProducts = [<span className={"h-2-5"} ref={this.props.bag["description"]}>∙{this.props.bag["description"]}</span>];
    }

    //Change for comments
    this.props.bag["comments"] && this.props.bag["comments"].forEach((com) => {
      contentComments.push(
        <div className="media-inf">

          <div className="media-body mg-l-15">
            <strong>{com["name"]}</strong>
            <span>{com["message"]}</span>
            <h6 className={"mg-0 ta-e"}>{moment(com["date"]).format("MMM DD hh:mm a")}</h6>
          </div>
        </div>
      );
    });

    //Change for califications
    let accStars = 0;
    this.props.bag["califications"] && this.props.bag["califications"].forEach((com) => {
      var countStars = parseInt(com["question_qualify"]) + parseInt(com["question_punctuality"]) + parseInt(com["question_expected"]) + parseInt(com["question_price"]) + parseInt(com["question_design"]);
      countStars = Math.ceil(countStars / 5);
      accStars += countStars
      var contentStars = []

      var xC = 0;
      for (xC = 0; xC < countStars; xC++) {
        contentStars.push(<span className={"estrella llena"} />)
      }
      for (xC = 0; xC < 5 - countStars; xC++) {
        contentStars.push(<span className={"estrella"} />)
      }
      contentCalifications.push(
        <div className="media-inf">

          <div className="media-body mg-l-15 content-califications">
            <strong>{com["name"]}</strong>
            <div className={"ct-cal-view0"}>
              {contentStars}
            </div>
            {this.props.questions &&
              <div className={"ct-cal-view1"}>
                <span className={"fz-xs d-f jc-sb"}>{this.props.questions["question_qualify"]}<span><span className={"estrella llena"}></span>{com["question_qualify"]}</span></span>
                <span className={"fz-xs d-f jc-sb"}>{this.props.questions["question_punctuality"]}<span><span className={"estrella llena"}></span>{com["question_punctuality"]}</span></span>
                <span className={"fz-xs d-f jc-sb"}>{this.props.questions["question_expected"]}<span><span className={"estrella llena"}></span>{com["question_expected"]}</span></span>
                <span className={"fz-xs d-f jc-sb"}>{this.props.questions["question_price"]}<span><span className={"estrella llena"}></span>{com["question_price"]}</span></span>
                <span className={"fz-xs d-f jc-sb"}>{this.props.questions["question_design"]}<span><span className={"estrella llena"}></span>{com["question_design"]}</span></span>
              </div>}

            <h6 className={"mg-0 ta-e"}>{moment(com["date"]).format("MMM DD hh:mm a")}</h6>
          </div>
        </div>
      );
    });
    let countStarsBreakfast = Math.ceil(accStars / this.props.bag["califications"].length);
    var x = 0;
    for (x = 0; x < countStarsBreakfast; x++) {
      contentStars.push(<span className={"estrella llena"}></span>)
    }
    x = 0;
    for (x = 0; x < 5 - countStarsBreakfast; x++) {
      contentStars.push(<span className={"estrella"}></span>);
    }

    this.disponibility = this.props.bag["quantity_store"] < 1;

    let contentAsset = (
      <a onClick={()=>{this.showPreviewImage()}} rel="noopener" href={`#/`} className={"c-b text-item"} >
        {
          this.props.bag["image"].length > 0 && this.props.bag["image"][0]["drive_key"] && this.props.bag["image"][0]["drive_key"].split("mp4").length > 1 ?
            <video className={`image-item`} controls src={`${Consts.urlStatic}${this.props.bag["image"][0]["drive_key"]}`} /> :
            <img alt={this.props.description} className={`image-item bs-cover ${this.props.bag["image"] && this.props.bag["image"].length > 0 ? "" : "bs-ct"}`} style={{ backgroundImage: `url(${Consts.urlStatic}${this.props.bag["image"] && this.props.bag["image"].length > 0 ? this.props.bag["image"][0]["drive_key"] : "images/im-n-disp.png"})` }}>
            </img>
        }
      </a>
    );

    return (<div className={"body-bag " + this.props.size}>
      {
        this.disponibility && <div onDoubleClick={(e) => { this.onViewInfoProd(e); window.location.hash = (`#/producto/${this.props.bag["title"]}`) }} className={"content-view-products content-sold-out d-f jc-c ai-c"}>
          <span> SOLD OUT</span>
        </div>
      }

      {this.state.viewProducts ? <div className={"content-view-products"}>
        <span className={"title-desc-b"}>Descripcion</span>
        <span onClick={this.viewProducts} className={"ic-close"}>x</span>
        <div className="content-contenido two-colums">
          {contentProducts}
        </div>
      </div> : []}
      {this.state.viewComments ? <div className={"content-view-products"}>
        <span className={"title-desc-b"}>Comentarios</span>
        <span onClick={this.viewComments} className={"ic-close"}>x</span>
        <div className="content-contenido">
          {contentComments}
        </div>
      </div> : []}
      {this.state.viewCalifications ? <div className={"content-view-products"}>
        <span className={"title-desc-b"}>Calificaciones</span>
        <span onClick={this.viewCalifications} className={"ic-close"}>x</span>
        <div className="content-contenido mg-1">
          {contentCalifications}
        </div>
      </div> : []}

      {this.classStore === "binstore" ? <div className={"icon-break-selected"}></div> : []}
      {this.classStore === "binstore" ? <div onClick={() => {
        this.classStore = "";
        this.props.onDelete(this.props.bag, false)
      }} className={"icon-close-selected"}>X</div> : []}

      <div id={`itemBreakfast${this.props.id}`} ref={(itemBreakfast) => { this.itemBreakfast = itemBreakfast; }} className={`item ${this.props.size} ${this.classStore}`}>
        <div className="head-item" onClick={this.addProductToStore}>
          <h4 className={"to-e m-0 text-item title-item"} alt={this.props.bag["title"]} >{this.props.bag["title"]} </h4>
          <h4 className={"text-item ta-c"}>{Session.instance.currencyUser === "COP" ? formatCop((this.props.bag["price"] || 0).toString()) : `$ ${this.state.priceUsd}`} {Session.instance.currencyUser}</h4>
          <div ref={(drone) => {
            this.droneS = drone;
          }} className={"dron-content pe-n"} width="100" height="100" border="0" />
        </div>
        {
          contentAsset
        }
        


        {
          /*
           <div className="content-contenido">
          {contentProducts}
        </div>
        */
        }
        <div className={'content-btns  '}>
          <div className={"fott-it-br"}>
            <div className={"ds-fl"}>
              <input type={"button"} value={`¿${Consts.txConten}?`} onClick={this.viewProducts} className="content-buttons cb-two">

              </input>
              <div onClick={this.disponibility ? () => { } : (e) => {
                this.classStore = "binstore";
                this.viewProducts(e);
                this.props.onAdd(this.props.bag, true);
                this.addProductToStore(e);

              }} className="content-buttons">
                <div className="add-product"></div>
                <h4>
                  {<a href={`${this.disponibility ? "#" : "#/tienda"}`} className={"c-b"}>{Consts.txBuy}</a>}</h4>

              </div>
            </div>
            <div className="chat-content-footer">
              {
                /*

                <div className={"ds-fl"}>

                <input type="text" className="ch-foot-inp" placeholder="Comentario" />
                <div className={"icontf ic-plus"}></div>

              </div>
                 */
              }
              <div onClick={this.viewCalifications} className="calificacion cur-p">
                {
                  contentStars
                }
              </div>
              <div className={"ds-fl cur-p"}>
                <div onClick={this.viewComments} className={"icontf ic-comment"}></div> <span className={"ind-cnt"}>{this.props.bag.comments && this.props.bag.comments.length}</span>
              </div>
              <h5 className="vendidos">{this.props.bag.sales} {Consts.txSold}</h5>

            </div>
          </div>

        </div>
        {
          this.props.bag.time_estimated > 0 && <div className={"ct-time-estimated"}><span className={"tx-time"}>{this.props.bag.time_estimated} H</span><div className={"icon-time"} /></div>
        }

        <a href={`#/producto/${this.props.bag["title"]}`} ><div onClick={this.onViewInfoProd} className={"ct-view-bag"}><div className={"icon-view"} /><span className={"tx-time"}>Abrir producto</span></div></a>
      </div>
    </div>
    );
  }

  viewProducts = (e) => {
    if (this.disponibility) {
      e.preventDefault();
      return;
    }
    console.warn("VIEW PRODUCTS");
    this.setState({
      viewProducts: !this.state.viewProducts
    });
  };
  viewComments = (e) => {
    if (this.disponibility) {
      e.preventDefault();
      return;
    }

    console.warn("VIEW viewComments");
    this.setState({
      viewComments: !this.state.viewComments
    });
  };
  viewCalifications = (e) => {
    if (this.disponibility) {
      e.preventDefault();
      return;
    }

    console.warn("VIEW califications");
    this.setState({
      viewCalifications: !this.state.viewCalifications
    });
  };

  addProductToStore = (e) => {

    if (this.disponibility) {
      e.preventDefault();
      return;
    }

    this.classStore = "binstore";


    this.props.onAdd(this.props.bag, false);
    this.viewProducts(e);

  }

  onViewInfoProd = (e) => {
    if (this.disponibility) {
      e.preventDefault();
      return;
    }

    Session.instance.viewProduct = this.props.bag;
  }

  showPreviewImage = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },
      position: "center-end",
      background: 'transparent',
      backdrop: `
        rgba(0,0,123,0.4)
        url(${Consts.urlStatic + this.props.bag["image"][0]["drive_key"]})
        center center
        no-repeat
      `,
      closeOnClickOutside: false,
      closeOnEsc: false,
      allowOutsideClick: false,
      
    });
  }
}
export default ItemBreakfast
