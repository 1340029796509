import React, {Component} from 'react';
import './type-services-content.scss';
import {Link} from "react-router-dom";
import SlideMenu from "../slide-menu";
import BusquedaComponent from '../busqueda-component';


class TypeServicesContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modV : 0
    };
  }

  changeMod = (v) => {
    this.setState({
      modV :v.id
    }, () => {
      this.props.changeView(v.title);
      return;
    });
  };

  render() {
    let modalities = this.props.modalities || [{"id":0,"title":"Corriente"},{"id":1,"title":"Sorpresa"},{"id":2,"title":"Empresarial",},{"id":3,"title":"Amor Verdadero",}];
    let contentMod = [<span className={`c-w ${this.props.colorTitle}`}><abbr title={"Que te trae por aqui hoy?"}>Modalidades</abbr></span>];
    if(this.props.modalities === undefined) {
      modalities = [{"id":0,"title":"Number one"},{"id":1,"title":"Number two"},{"id":2,"title":"Number three",},{"id":3,"title":"Number Four",}];
    }
    if(modalities.length > 0) {
      modalities.forEach((moda) =>  {
        contentMod.push(
          <Link to={`/productos/${moda["title"]}`}>
            <li >
              <div className={"ic-services-ic"}></div>
              <h3 className={moda["title"] !== undefined && this.props.modalitie !== undefined  ? moda["title"].toLowerCase() === this.props.modalitie.toLowerCase() ? "is-active" : "": ""}>
                <abbr title={`Filtrar: producto ${moda["title"]}`}>{moda["title"]} </abbr>
              </h3>
            </li>
          </Link>);
      });
    }

    return(
      <ul className={"content-services"}>
        <BusquedaComponent onSearch={(textToSearch)=>{this.props.onSearch(textToSearch);}} />
        <SlideMenu showContent={()=> {this.props.showContent();}} modalities={modalities}  modalitie={this.props.modalitie} />

      </ul>
    );
  }
}

export default TypeServicesContent;
