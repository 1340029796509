import axios from "axios";
import * as Consts from '../pages/common/constants/const';
import Swal from 'sweetalert2';
import Session from "../sandbox/session";

let BASEURL = Consts.urlBase;

// export const apiOnGenerateBankPay = (bodyPay) => {

//   axios.post(`${Consts.urlBase}transactions/${Consts.slugCountry}/`, JSON.stringify(bodyPay), {}).then((complete) => {
//       let textAlert = "Your pay has been completed.";
//       if (complete["status"] === 201) {
//         let urlPse = complete["data"]["transactionResponse"]["extraParameters"]["BANK_URL"];
//         textAlert = "Your pay has been pending.";
//         window.open(urlPse, "_blank");
//       }
//       Swal.fire({
//         title: Consts.txComp,
//         text: textAlert,
//         icon: 'success',
//         confirmButtonText: Consts.txCont,
//       }).then((result) => {
//         if (result.value) {
//           localStorage.removeItem('itemsshop');
//           window.location.pathname = "/index.html";
//         }
//       })
//       return complete;
//     }
//   ).catch((error) => {
//     Swal.fire(
//       Consts.txComp,
//       Consts.txPayIncomp,
//       'error'
//     )
//     return error
//   });

//   Swal.fire({
//     title: Consts.txPayProg,
//     text: '',
//     icon: 'success',
//     showCancelButton: false,
//     showConfirmButton: false,
//     cancelable: false,
//     allowOutsideClick: false,
//     confirmButtonText: Consts.txCont,
//   }).then((result) => {
//     if (result.value) {

//     } else if (result.dismiss === Swal.DismissReason.cancel) {
//       Swal.fire(
//         'Cancelled',
//         'Your pay is cancelled!)',
//         'error'
//       )
//     }
//   })
// };

// export const apiOnGenerateCCPay = (bodyPay) => {
//   axios.post(`${Consts.urlBase}transactions/${Consts.slugCountry}/`, JSON.stringify(bodyPay), {}).then((complete) => {
//       if (complete) {
//         //sandbox_init_point, url_continue
//         if(complete.data['url_continue'] !== undefined) {
//           var child = window.open(complete.data['url_continue'], '_blank').focus();
//           var timer = setInterval(checkChild, 3000);

//           function checkChild() {
//             if (child.closed) {
//               alertPayCompleted();
//               clearInterval(timer);
//             }
//           }
//           // window.location = complete.data['sandbox_init_point'];
//         } else {
//           if (bodyPay["verification"]["paymentMethod"] === 'cash') {
//             alertPayCompleted();
//           }

//         }
//         function alertPayCompleted(){
//           Swal.fire({
//             title: Consts.txComp,
//             text: Consts.txPayComp,
//             icon: 'success',
//             confirmButtonText: Consts.txCont,
//           }).then((result) => {
//             if (result.value) {
//               localStorage.removeItem('itemsshop');
//               window.location.hash = "/";
//             }
//           })
//         }
//         return complete;
//       }
//     }
//   ).catch((error) => {
//     Swal.fire(
//       Consts.txComp,
//       Consts.txPayIncomp,
//       'error'
//     )
//     return error
//   });
//   Swal.fire({
//     title: Consts.txPayProg,
//     text: '',
//     icon: 'success',
//     showCancelButton: false,
//     showConfirmButton: false,
//     cancelable: false,
//     allowOutsideClick: false,
//     confirmButtonText: Consts.txCont
//   }).then((result) => {
//     if (result.value) {

//     } else if (result.dismiss === Swal.DismissReason.cancel) {
//       Swal.fire(
//         'Cancelled',
//         'Your pay is cancelled!)',
//         'error'
//       )
//     }
//   })
// };

export const apiGetTypes = (modalitie) => {
  const url = BASEURL + 'bags/' + Consts.slugFactory + '/categories/' + modalitie + '/';
  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['results'];
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetOcassions = (ocassion) => {
  const url = BASEURL + 'bags/' + Consts.slugFactory + '/category/' + ocassion + "/";
  return axios.get(url, {}).then(
    (response) => {
      if (response) {
        return response['data']['results'];
      }
    }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetBreakfasts = (filter) => {
 
  const url = filter && filter.indexOf("http") !== -1 ? filter : filter === "undefined" ? BASEURL + "bags/" + Consts.slugFactory + "/" : BASEURL + "bags/" + Consts.slugFactory + "/" + filter + "/";
  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetBag = async (title) => {
  const url = BASEURL + "bags/" + Consts.slugFactory + "/" + title + "/";

  return await axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetProductsSuggestions = (filter) => {
  const url = BASEURL + "suggestions/bags/";

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['results']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetProductSuggestions = (filter) => {
  const url = BASEURL + "suggestions/bag/";

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['bag'][0]
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetQuestions = () => {
  const url = BASEURL + `questions/${Consts.slugCountry}/`;

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['results'][0]
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetCountry = () => {
  const url = BASEURL + `country/${Consts.slugCountry}/`;

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['country'][0]
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetCountries = () => {
  const url = BASEURL + `countries/`;

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      Session.instance.countries = response['data']['countries'];
      return response['data']['countries']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetCities = (country) => {
  const url = BASEURL + `country/${country}/cities/`;

  return axios.get(url, {
    headers: {},
  }).then((response) => {
    if (response) {
      return response['data']['cities']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });

};

export const apiGetBankList = (body) => {
  const url = Consts.urlBanks;

  return axios.post(url, body, {}).then((response) => {
    if (response) {
      return response['data']["response"]['banks']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetCategories = async () => {
  const url = BASEURL + "categories/";

  return await axios.get(url, {}).then((response) => {
    if (response) {
      return response['data']['results']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetProductsBySubCategory = async (category) => {
  const url = BASEURL + `filter/${Consts.slugFactory}/subcategory/${category}/`;

  return await axios.get(url, {}).then((response) => {
    if (response) {
      console.warn("apiGetProductsBySubCategory", response);
      if (response['data']['results'].length === 0) {
        Swal.fire({
          title: 'No se han encontrado productos .',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }

      return response['data']['results']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiGetModalities = () => {
  const url = BASEURL + "modalities/" + Consts.slugFactory + "/";

  return axios.get(url, {}).then((response) => {
    if (response) {
      return response['data']['results']
    }
  }).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiOnGeneratePay = (bodyPay) => {
  const url = BASEURL + "api/transactions/" + Consts.slugCountry + "/";

  return axios.post(url, JSON.stringify(bodyPay), {}).then((response) => {
      if (response) {
        return response['data']['results']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiOnGetEntries = async (country) => {
  const url = `${BASEURL}entries/${country}/`;

  return await axios.get(url, {}, {}).then((response) => {
      if (response) {
        return response['data']['entries']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });

};

export const apiOnGetEntry = async (country, title) => {
  const url = `${BASEURL}entry/${country}/${title}/`;

  return await axios.get(url, {}, {}).then((response) => {
      if (response) {
        return response['data']['entry']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });

};

export const apiOnGetLastSales = async (country, slugFactory) => {
  const url = `${BASEURL}lastsales/${Consts.slugCountry}/${Consts.slugFactory}/`;
  return await axios.get(url, {}, {}).then((response) => {
      if (response) {
        return response['data']['results']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiOnGetPromotions = async (country, slugFactory) => {
  const url = `${BASEURL}promotions/${Consts.slugCountry}/${Consts.slugFactory}/`;
  return await axios.get(url, {}, {}).then((response) => {
      if (response) {
        return response['data']['results']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });
};

export const apiOnSearchProducts = async (country, textSearch) => {
  const url = `${BASEURL}search/${Consts.slugCountry}/${textSearch}/`;
  return await axios.get(url, {}, {}).then((response) => {
      if (response) {
        return response['data']['results']
      }
    }
  ).catch((error) => {
    if (error) {
      return error
    }
  });
};

